// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-get-started-js": () => import("./../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-get-started-success-js": () => import("./../src/pages/get-started-success.js" /* webpackChunkName: "component---src-pages-get-started-success-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-procurement-js": () => import("./../src/pages/procurement.js" /* webpackChunkName: "component---src-pages-procurement-js" */),
  "component---src-pages-operational-resilience-js": () => import("./../src/pages/operational-resilience.js" /* webpackChunkName: "component---src-pages-operational-resilience-js" */),
  "component---src-pages-compliance-js": () => import("./../src/pages/compliance.js" /* webpackChunkName: "component---src-pages-compliance-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-white-paper-js": () => import("./../src/pages/white-paper.js" /* webpackChunkName: "component---src-pages-white-paper-js" */),
  "component---src-pages-white-paper-success-js": () => import("./../src/pages/white-paper-success.js" /* webpackChunkName: "component---src-pages-white-paper-success-js" */),
  "component---src-pages-blog-blog-js": () => import("./../src/pages/blog/blog.js" /* webpackChunkName: "component---src-pages-blog-blog-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-tag-js": () => import("./../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-blog-author-js": () => import("./../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shared-components-globe-2-js": () => import("./../src/pages/shared/components/globe-2.js" /* webpackChunkName: "component---src-pages-shared-components-globe-2-js" */),
  "component---src-pages-shared-components-globe-js": () => import("./../src/pages/shared/components/globe.js" /* webpackChunkName: "component---src-pages-shared-components-globe-js" */),
  "component---src-pages-shared-components-newsletter-js": () => import("./../src/pages/shared/components/newsletter.js" /* webpackChunkName: "component---src-pages-shared-components-newsletter-js" */),
  "component---src-pages-shared-components-sign-up-form-component-js": () => import("./../src/pages/shared/components/sign-up-form.component.js" /* webpackChunkName: "component---src-pages-shared-components-sign-up-form-component-js" */),
  "component---src-pages-shared-layout-cta-js": () => import("./../src/pages/shared/layout/cta.js" /* webpackChunkName: "component---src-pages-shared-layout-cta-js" */),
  "component---src-pages-shared-layout-footer-js": () => import("./../src/pages/shared/layout/footer.js" /* webpackChunkName: "component---src-pages-shared-layout-footer-js" */),
  "component---src-pages-shared-layout-layout-dark-js": () => import("./../src/pages/shared/layout/layout-dark.js" /* webpackChunkName: "component---src-pages-shared-layout-layout-dark-js" */),
  "component---src-pages-shared-layout-layout-js": () => import("./../src/pages/shared/layout/layout.js" /* webpackChunkName: "component---src-pages-shared-layout-layout-js" */),
  "component---src-pages-shared-layout-navbar-js": () => import("./../src/pages/shared/layout/navbar.js" /* webpackChunkName: "component---src-pages-shared-layout-navbar-js" */)
}

